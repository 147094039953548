var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CrearModal',{attrs:{"modal":_vm.CreModal},on:{"child-refresh":function($event){_vm.refrescarDocs=true},"cerrarModal":function($event){_vm.CreModal=false}}}),_c('DocumentosModal',{attrs:{"modal":_vm.ShowDocModal,"documentItem":_vm.documentItem,"title":_vm.titleModal},on:{"update:modal":function($event){_vm.ShowDocModal=$event},"close":_vm.closeModal}}),_c('ListarModal',{attrs:{"modal":_vm.ShowListModal,"listItem":_vm.listItem,"title":_vm.titleModal2},on:{"update:modal":function($event){_vm.ShowListModal=$event},"close":_vm.closeModal}}),_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(" "+_vm._s(_vm.$t('label.standardDocumentation'))+" ")])]),_c('CCardBody',[_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          placement: 'top-end',
          content: _vm.$t('label.standardDocumentation')
        }),expression:"{\n          placement: 'top-end',\n          content: $t('label.standardDocumentation')\n        }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){_vm.CreModal = true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nueva'))+" ")],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.itemsDocs,"fields":_vm.fieldsDoc,"loading":_vm.LoadingDocs,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(_vm.$t('label.edit')+' '+_vm.$t('label.standardDocumentation')),expression:"$t('label.edit')+' '+$t('label.standardDocumentation')"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){_vm.CreModal = item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(item.FgActStandardDocument)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(_vm.$t('label.uploadCommodityDocuments')),expression:"$t('label.uploadCommodityDocuments')"}],staticClass:"mr-1",attrs:{"color":"add","size":"sm","square":""},on:{"click":function($event){return _vm.docModal(item)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  placement:'top-end',
                  content:_vm.$t('label.toView')+' '+_vm.$t('label.documents')
                }),expression:"{\n                  placement:'top-end',\n                  content:$t('label.toView')+' '+$t('label.documents')\n                }"}],attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.ListModal(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }